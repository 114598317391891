import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HomeScreen from './components/HomeScreen';
import ViewLink from './components/ViewLink';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact path = "/" component = {HomeScreen} />
      <Route exact path = "/view" component = {ViewLink} />
   </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
