import React from 'react';
import homeLogo from './../assets/images/1472752686_home.png';
import medicareLogo from './../assets/images/1471696088_Quote.png';
import scheduleLogo from './../assets/images/1471695237_Calender_2.png';
import './../css/ViewLink.css';
import { useHistory } from "react-router-dom";

const ViewLink = () => {
    let history = useHistory();

    function handleViewClick(e) {
        e.preventDefault();
        console.log("efwegifgiwegifgiweufiu");
        history.push("/");
    }

    return (
        <div className="view__root">
            <iframe src="https://www.pexels.com" className="view__iframe"></iframe>
            <div className="button__wrapper">
                <button onClick={handleViewClick}><img src={homeLogo} className="buttonImage" onClick={handleViewClick}/></button>
                <button onClick={handleViewClick}><img src={medicareLogo} className="buttonImage" onClick={handleViewClick}/></button>
                <button onClick={handleViewClick}><img src={scheduleLogo} className="buttonImage" onClick={handleViewClick}/></button>
            </div>
        </div>
    );
}

export default ViewLink;