import React, { useEffect } from "react";
import IPLANRX_LOGO from "./../assets/images/iplan_vector_horiz_WHITE.png";
import P360_KIOSK_LOGO from "./../assets/images/Prevea360_Medicare_Tag_Logo.png"
import DEAN_KIOSK_LOGO from "./../assets/images/SSM_DeanHealthPlan_Horz_RGB_No_R.png"
import medicareLogo from "./../assets/images/1471696088_Quote.png";
import scheduleLogo from "./../assets/images/1471695237_Calender_2.png";
import "./../css/HomeScreen.css";
import { useHistory } from "react-router-dom";
import * as qs from 'query-string';
import config from './../config';
const HomeScreen = (props) => {
  let history = useHistory();
  const parsedUrl = qs.parse(window.location.search);
  let accessSource = null;
  const webUrl = window.location.href;

  if(webUrl.includes("deankiosk.com")) {
    accessSource = "DEAN_KIOSK";
   
  }else if(webUrl.includes("p360kiosk.com")) {
    accessSource = "P360_KIOSK";
  }
  if(!accessSource) {
    accessSource = "P360_KIOSK";
  }

  useEffect(() => {
    window.analytics.identify(parsedUrl.utm_campaign, {
      utm_campaign: parsedUrl.utm_campaign,
      utm_medium: parsedUrl.utm_medium
    });
    refreshAt(2,0,0);
  });
  let buttonUrls = {
    P360_KIOSK: {
      medicareButton: 'https://www.prevea360.com/Medicare/Shop-Medicare-Plans.aspx',
      allOptions: 'https://www.medicare.gov/plan-compare/#/?lang=en&year=2021',
      under65Options: 'https://www.healthsherpa.com/?_agent_id=thomas-madden',
      scheduleMeeting: 'https://calendly.com/iplanrx-open-enrollment-team/2020-myhealth-concierge-simple-safe-enrollment'
    },
    DEAN_KIOSK:{
      medicareButton: 'http://www.deancare.com/Medicare/',
      allOptions: 'https://www.medicare.gov/plan-compare/#/?lang=en&year=2021',
      under65Options: 'https://www.healthsherpa.com/?_agent_id=thomas-madden',
      scheduleMeeting: 'https://calendly.com/iplanrx-open-enrollment-team/myhealth-simple-safe-enrollment-deanssm'
    }
  }

  let buttonEvents = {
    P360_KIOSK: {
      medicareButton: "Prevea's 360 MAPD",
      allOptions: "All Medicare Options",
      under65Options: "ACA Marketplace",
      scheduleMeeting: "Schedule Meeting"
    },
    DEAN_KIOSK:{
      medicareButton: "Medicare - Deancare Health Plan",
      allOptions: "All Medicare Options - Deancare Health Plan",
      under65Options: "Deancare Health Plan - ACA Marketplace",
      scheduleMeeting: "Deancare Health Plan - Schedule Meeting"
    }
  }

  //Setting webpage logo


  function refreshAt(hours, minutes, seconds) {
    var now = new Date();
    var then = new Date();

    if(now.getHours() > hours ||
       (now.getHours() == hours && now.getMinutes() > minutes) ||
        now.getHours() == hours && now.getMinutes() == minutes && now.getSeconds() >= seconds) {
        then.setDate(now.getDate() + 1);
    }
    then.setHours(hours);
    then.setMinutes(minutes);
    then.setSeconds(seconds);

    var timeout = (then.getTime() - now.getTime());
    setTimeout(function() { window.location.reload(true); }, timeout);
  }

  function generateUID(length)
  {
      return window.btoa(Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2))).map((b) => String.fromCharCode(b)).join("")).replace(/[+/]/g, "").substring(0, length);
  }

  async function handleClick(buttonTitle) {
    console.log(buttonTitle);
    // console.log(mixpanel);
    let date = new Date();
    let timestamp = date.getTime();
    switch (buttonTitle) {
      case 'medicareButton':
        // await Mixpanel.track("Prevea's 360 MAPD");
        await window.analytics.track(buttonEvents[accessSource][buttonTitle]);
        window.location.href = buttonUrls[accessSource][buttonTitle];
        break;
      case 'allOptions':
        // await Mixpanel.track("All Medicare Options");
        await window.analytics.track(buttonEvents[accessSource][buttonTitle]);
        window.location.href = buttonUrls[accessSource][buttonTitle];
        break;
      case 'under65Options':
        // await Mixpanel.track("ACA Marketplace");
        await window.analytics.track(buttonEvents[accessSource][buttonTitle]);
        window.location.href = buttonUrls[accessSource][buttonTitle];
        break;
      case 'scheduleMeeting':
        // await Mixpanel.track("Schedule Meeting");
        await window.analytics.track(buttonEvents[accessSource][buttonTitle]);
        window.location.href = buttonUrls[accessSource][buttonTitle];
        break;
      default:
        break;
    }
  }

  return (
    <div id="home">
      <div class="overlay">
      <div className="container__iprx">
        <p className="Headline">myHealth Compare Concierge</p>
        {
          accessSource == 'P360_KIOSK' &&
          <p className="HeadlinesubText">In partnership with Prevea360 Health Plan</p>
        }
        {
          accessSource == 'DEAN_KIOSK' &&
            <p className="HeadlinesubText">In partnership with DeanHealthPlan<br/><span style={{marginLeft:'12%'}}>A member of SSM Health</span></p>

        }
        <div className="image-wrapper">
          <img src={IPLANRX_LOGO} className="image" />
          <img src={accessSource == 'P360_KIOSK' ? P360_KIOSK_LOGO : DEAN_KIOSK_LOGO } className="image" />
        </div>
        <div className="text-center headline">
          <center>
            <p>
              <i>
                Empowering Individuals to Make Better Health and Wealth Decisions
              </i>
            </p>
          </center>
          <center>
            <p>Safe and Simple Enrollment</p>
          </center>
          <center>
            <p>
              Virtual and Local In-Person Support
            </p>
          </center>
          <center>
            <p>
              Customized Benefits that Fit{" "}
                <i>Your Budget!</i>
            </p>
          </center>
        </div>
        <div className="buttonWrapper">
          <button className="btn1" 
            style={{backgroundColor:accessSource == 'P360_KIOSK'?'#B2BB1E':'#661c78'}} 
            onClick={() => handleClick('medicareButton')}
          >
            <img src={medicareLogo} className="icon-logo" />
            <p className="header__title">
              <b>
              Are you on Medicare?
              </b>
            </p>
            {
              accessSource == 'P360_KIOSK' &&
              <p>
              We’ve got more information on Prevea360 coming soon!
              Schedule an appointment today to get the details
              </p>
            }
            {
              accessSource == 'DEAN_KIOSK' &&
              <p>
              We’ve got more information on Dean Medicare Advantage Plans!
            Schedule an appointment today to get the details
              </p>

            }
            {/* <p>
            We’ve got more information on Prevea360 coming soon!
            Schedule an appointment today to get the details
            </p> */}
            <p>
              {
                accessSource == 'P360_KIOSK' &&
                <b>
                  SCHEDULE NOW
                </b>
              }
            </p>
          </button>
          <div className="centerButtonWrapper">
            <button className="btn2"
            style={{backgroundColor:accessSource == 'P360_KIOSK'?'#B2BB1E':'#661c78'}} 
             onClick={() => handleClick('allOptions')}>
              <p> All Medicare Options</p>
            </button>
            <button className="btn3"
            style={{backgroundColor:accessSource == 'P360_KIOSK'?'#B2BB1E':'#661c78'}} 
            onClick={() => handleClick('under65Options')}>
              <p className="header__title">
                <b>Under Age 65 Options</b>
                <br />
                ACA Marketplace
              </p>
            </button>
          </div>
          <button
            id="shedule-meeting-home-btn"
            className="btn4"
            style={{backgroundColor:accessSource == 'P360_KIOSK'?'#B2BB1E':'#661c78'}} 
            onClick={() => handleClick('scheduleMeeting')}
          >
            <img src={scheduleLogo} className="icon-logo" />
            <p>
              Schedule In-person or <br/>Virtual Meeting
              <br />
              <br />
              <b>Need Help - No Problem</b>
              <br />
              {
                accessSource == 'P360_KIOSK' &&
                'Call us at: 920-430-0299'
              }
              {
                accessSource == 'DEAN_KIOSK' &&
                'Call us at: 608-336-3233'
              }
            </p>
          </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default HomeScreen;
